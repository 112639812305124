import _ from 'lodash';
import cookies from 'js-cookie';
import React from 'react';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split, from } from 'apollo-link';
import { ApolloProvider } from 'react-apollo';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { toIdValue, getMainDefinition } from 'apollo-utilities';

import Globals from '~web-core/lib/common/globals';

/* eslint-disable no-param-reassign */
export function decodeEntities(result, value, key) {
  if (!value) {
    result[key] = value;
    return;
  } else if (typeof value === 'string') {
    result[key] = _.unescape(value);
    return;
  } else if (typeof value === 'object') {
    result[key] = _.transform(value, decodeEntities);
    return;
  }

  result[key] = value;
}
/* eslint-enable no-param-reassign */

const logoutLink = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 498) {
    cookies.remove('token', {
      path: '/',
      domain: Globals.COOKIE_DOMAIN,
    });
  }
});

let wsUrl = Globals.API_CORE_DOMAIN.replace('https', 'wss');
if (Globals.ENV === 'local') {
  wsUrl = `ws://${Globals.COOKIE_DOMAIN}`;
}

export const subClient = new SubscriptionClient(`${wsUrl}:2096/subscriptions`, {
  reconnect: true,
  lazy: true,
});

subClient.use([
  {
    applyMiddleware(opts, next) {
      const token = cookies.get('token', '/');
      if (token) {
        opts.authToken = token;
      }
      next();
    },
  },
]);

const wsLink = new WebSocketLink(subClient);

// const customFetch = (uri, opts) => {
//   const options = opts;
//   let apiUri = `${Globals.API_CORE_DOMAIN}/graphql`;

//   if (options.headers['is-admin']) {
//     options.headers = _.omit(options.headers, ['is-admin']);
//     apiUri = `${Globals.API_CORE_DOMAIN}/super-graphql`;
//   }
//   return fetch(apiUri, options);
// };

const httpLink = new HttpLink({
  uri: `${Globals.API_CORE_DOMAIN}/graphql`,
  credentials: 'include',
});

const dataLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = cookies.get('token', '/');
  if (token) {
    operation.setContext(() => ({
      headers: {
        'x-flip-token': token,
      },
    }));
  }
  return forward(operation);
});

function dataIdFromObject(object) {
  if (object.__typename && object.uuid) return `${object.__typename}:${object.uuid}`; // eslint-disable-line no-underscore-dangle
  if (object.__typename && object.id) return `${object.__typename}:${object.id}`; // eslint-disable-line no-underscore-dangle
  return null;
}

window.APOLLO_STATE = _.transform(window.APOLLO_STATE, decodeEntities);
const client = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject,
    cacheRedirects: {
      Viewer: {
        flip: (root, args) => toIdValue(dataIdFromObject({ __typename: 'Flip', uuid: args.uuid })),
        deposit: (root, args) => toIdValue(dataIdFromObject({ __typename: 'Deposit', uuid: args.uuid })),
        incomeSource: (root, args) => toIdValue(dataIdFromObject({ __typename: 'PrivateIncomeSource', uuid: args.uuid })),
        listing: (root, args) => toIdValue(dataIdFromObject({ __typename: 'PrivateListing', uuid: args.uuid })),
      },
      Flip: {
        deposit: (root, args) => toIdValue(dataIdFromObject({ __typename: 'Deposit', uuid: args.uuid })),
        document: (root, args) => toIdValue(dataIdFromObject({ __typename: 'FlipDocument', uuid: args.uuid })),
      },
    },
  }).restore(window.APOLLO_STATE),
  link: from([authMiddleware, logoutLink, dataLink]),
});

export default function Apollo(props) {
  return (
    <ApolloProvider {...props} client={client} />
  );
}
