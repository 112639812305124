import _ from 'lodash';
import queryString from 'query-string';
import { Component, ReactNode } from 'react';
import { Cookies, withCookies } from 'react-cookie';
import { RouteComponentProps } from 'react-router';

import Globals from '~web-core/lib/common/globals';

interface Props extends RouteComponentProps {
  allCookies: { [name: string]: string };
  children: ReactNode;
  cookies: Cookies,
}

class App extends Component<Props> {
  componentDidMount() {
    const oneYearInSeconds = 31540000;
    const urlSearch = queryString.parse(this.props.location.search);

    if (urlSearch.utm_source) {
      const utmData = {
        utmSource: urlSearch.utm_source || null,
        utmMedium: urlSearch.utm_medium || null,
        utmTerm: urlSearch.utm_term || null,
        utmContent: urlSearch.utm_content || null,
        utmCampaign: urlSearch.utm_campaign || null,
      };

      _.map(utmData, (value, key) => {
        if (value) {
          this.props.cookies.set(key, value, {
            domain: Globals.COOKIE_DOMAIN,
            maxAge: oneYearInSeconds,
            path: '/',
          });
        }
      });

      this.props.cookies.set('utmSetAt', new Date(), {
        domain: Globals.COOKIE_DOMAIN,
        maxAge: oneYearInSeconds,
        path: '/',
      });
    }

    if (!this.props.allCookies['search-intro-hidden']) {
      this.props.cookies.set('search-intro-hidden', true, {
        domain: Globals.COOKIE_DOMAIN,
        maxAge: oneYearInSeconds * 2,
        path: '/',
      });
    }
  }

  componentDidUpdate(prevProps) {
    // Scroll to the top on a route change
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withCookies(App);
