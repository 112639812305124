import _ from 'lodash';
import React from 'react';
import { Route, Switch, withRouter } from 'react-router';

import App from '~web-core/lib/common/App';

const AppWithRouter = withRouter(App);

const RouteRenderer = props => (
  <AppWithRouter>
    <Switch>
      {_.map(
        props.routes,
        (Component, path) => (
          <Route
            exact
            key={path}
            path={path}
            // eslint-disable-next-line react/jsx-no-bind
            render={routeProps => <Component {...routeProps} />}
          />
        ),
      )}
    </Switch>
  </AppWithRouter>
);

export default RouteRenderer;
