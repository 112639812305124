import loadable from '@loadable/component';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Globals from '~web-core/lib/common/globals';

import Redirect from '~tools/react/components/utility/Redirect';

const LegacyRouter = loadable(() => import(/* webpackChunkName: "legacy-routes" */'~web-core/lib/_common/components/app/RouteRenderer'));

export default {
  //
  // Primary marketing routes
  '/': loadable(() => import(/* webpackChunkName: "home" */'~web-core/lib/common/scenes/Home')),
  '/landlord': () => <Redirect to="/" />,
  '/jobs': loadable(() => import(/* webpackChunkName: "jobs" */'~web-core/lib/common/scenes/Jobs')),
  '/about': loadable(() => import(/* webpackChunkName: "about" */'~web-core/lib/common/scenes/About')),
  '/partners': loadable(() => import(/* webpackChunkName: "partners" */'~web-core/lib/common/scenes/Partners')),
  '/pricing': loadable(() => import(/* webpackChunkName: "pricing" */'~web-core/lib/common/scenes/Pricing')),
  // '/plans/owner': loadable(() => import(/* webpackChunkName: "lite" */'~web-core/lib/common/scenes/Owner')),
  // '/plans/entrepreneur': loadable(() => import(/* webpackChunkName: "advanced" */'~web-core/lib/common/scenes/Advanced')),
  '/request-demo': loadable(() => import(/* webpackChunkName: "request-demo" */'~web-core/lib/common/scenes/RequestDemo')),
  '/privacy': loadable(() => import(/* webpackChunkName: "jobs" */'~web-core/lib/common/scenes/Privacy')),
  '/property-records': loadable(() => import(/* webpackChunkName: "jobs" */'~web-core/lib/common/scenes/PropertyRecords')),
  '/property-records/georgia/atlanta': loadable(() => import(/* webpackChunkName: "jobs" */'~web-core/lib/common/scenes/PropertyRecordsAtlanta')),

  //
  // Public app routes
  '/listing/:slugOrUuid': (props: RouteComponentProps<{ slugOrUuid: string }>) => <Redirect to={`${Globals.WEB_APARTMENT_DOMAIN}/listings/${props.match.params.slugOrUuid}`} />,
  '/s/:slug': (props: RouteComponentProps<{ slug: string }>) => <Redirect to={`${Globals.WEB_APARTMENT_DOMAIN}/s/${props.match.params.slug}`} />,
  '/s': () => <Redirect to={`${Globals.WEB_APARTMENT_DOMAIN}/s`} />,
  // '/building/:slugOrUuid': loadable(() => import(/* webpackChunkName: "building" */'~web-core/lib/common/scenes/Building')),
  // '/listing/:slugOrUuid/:tab?': loadable(() => import(/* webpackChunkName: "listing" */'~web-core/lib/common/scenes/Listing')),
  // '/s/:slug?': loadable(() => import(/* webpackChunkName: "search" */'~web-core/lib/common/scenes/Search')),

  //
  // Authentication routes
  '/login': loadable(() => import(/* webpackChunkName: "login" */'~tools/react/scenes/Login')),
  '/signup': loadable(() => import(/* webpackChunkName: "register" */'~tools/react/scenes/Register')),
  '/reset': loadable(() => import(/* webpackChunkName: "reset-password" */'~tools/react/scenes/ResetPassword')),
  '/reset/:token': loadable(() => import(/* webpackChunkName: "change-password" */'~tools/react/scenes/ChangePassword')),

  //
  // SEO marketing routes
  '/corporate-housing': loadable(() => import(/* webpackChunkName: "corporate-housing" */'~web-core/lib/common/scenes/CorporateHousing')),
  '/how-much-rent-can-i-afford': loadable(() => import(/* webpackChunkName: "how-much-rent-can-i-afford" */'~web-core/lib/common/scenes/RentCalculator')),
  '/lease-break': loadable(() => import(/* webpackChunkName: "lease-transfer" */'~web-core/lib/common/scenes/LeaseBreak')),
  '/managed-by-caretaker': loadable(() => import(/* webpackChunkName: "managed-by-flip" */'~web-core/lib/common/scenes/ManagedByFlip')),
  '/no-fees-rentals': loadable(() => import(/* webpackChunkName: "no-fees-rentals" */'~web-core/lib/common/scenes/NoFeesRentals')),
  '/pet-friendly-sublets': loadable(() => import(/* webpackChunkName: "pet-friendly-sublets" */'~web-core/lib/common/scenes/PetFriendlySublets')),
  '/roommate-agreement': loadable(() => import(/* webpackChunkName: "roommate-agreement" */'~web-core/lib/common/scenes/RoommateAgreement')),
  '/roommate-agreement/customize/:tab': loadable(() => import(/* webpackChunkName: "roommate-agreement" */'~web-core/lib/common/scenes/RoommateAgreementWizard')),
  '/short-term-rentals': loadable(() => import(/* webpackChunkName: "short-term-rentals" */'~web-core/lib/common/scenes/ShortTermRentals')),

  //
  // Protected routes
  '/activate': loadable(() => import(/* webpackChunkName: "activate-account" */'~web-core/lib/common/scenes/ActivateAccount')),
  '/verify-listing': loadable(() => import(/* webpackChunkName: "verify-listing" */'~web-core/lib/common/scenes/VerifyListing')),
  '/verify-email/:token?': loadable(() => import(/* webpackChunkName: "verify-email" */'~web-core/lib/common/scenes/VerifyEmail')),

  //
  // Dashboard routes
  '/account/:tab?': (props: RouteComponentProps<{ tab: string; }>) => <Redirect to={`${Globals.WEB_APARTMENT_DOMAIN}/account${props.match.params.tab ? `/${props.match.params.tab}` : ''}`} />,
  '/applications': () => <Redirect to={`${Globals.WEB_APARTMENT_DOMAIN}/applications`} />,
  '/applications/:flipUuid': (props: RouteComponentProps<{ flipUuid: string; }>) => <Redirect to={`${Globals.WEB_APARTMENT_DOMAIN}/applications/${props.match.params.flipUuid}`} />,
  '/applications/:flipUuid/:tab/:tabUuid?': (props: RouteComponentProps<{ flipUuid: string; tab: string; tabUuid: string; }>) => <Redirect to={`${Globals.WEB_APARTMENT_DOMAIN}/applications/${props.match.params.flipUuid}/${props.match.params.tab}${props.match.params.tabUuid ? `/${props.match.params.tabUuid}` : ''}`} />,
  '/bookmarks': loadable(() => import(/* webpackChunkName: "bookmarks" */'~web-core/lib/common/scenes/DashboardBookmarks')),
  '/listing-questions/:tab?': (props: RouteComponentProps<{ tab: string; }>) => <Redirect to={`${Globals.WEB_APARTMENT_DOMAIN}/questions${props.match.params.tab ? `/${props.match.params.tab}` : ''}`} />,
  '/rentals': () => <Redirect to={`${Globals.WEB_APARTMENT_DOMAIN}/rentals`} />,
  '/rentals/:leaseUuid/application': (props: RouteComponentProps<{ leaseUuid: string; }>) => <Redirect to={`${Globals.WEB_APARTMENT_DOMAIN}/rentals/${props.match.params.leaseUuid}/overview`} />,
  '/rentals/:leaseUuid/documents': (props: RouteComponentProps<{ leaseUuid: string; }>) => <Redirect to={`${Globals.WEB_APARTMENT_DOMAIN}/rentals/${props.match.params.leaseUuid}/documents`} />,
  '/rentals/:leaseUuid/documents/:documentUuid': (props: RouteComponentProps<{ leaseUuid: string; documentUuid: string; }>) => <Redirect to={`${Globals.WEB_APARTMENT_DOMAIN}/rentals/${props.match.params.leaseUuid}/documents/${props.match.params.documentUuid}`} />,
  '/rentals/:leaseUuid/overview': (props: RouteComponentProps<{ leaseUuid: string; }>) => <Redirect to={`${Globals.WEB_APARTMENT_DOMAIN}/rentals/${props.match.params.leaseUuid}/overview`} />,
  '/rentals/:leaseUuid/payments': (props: RouteComponentProps<{ leaseUuid: string; }>) => <Redirect to={`${Globals.WEB_APARTMENT_DOMAIN}/rentals/${props.match.params.leaseUuid}/payments`} />,
  '/rentals/:leaseUuid/rent-payment/:rentPaymentUuid': (props: RouteComponentProps<{ leaseUuid: string; rentPaymentUuid: string; }>) => <Redirect to={`${Globals.WEB_APARTMENT_DOMAIN}/rentals/${props.match.params.leaseUuid}/rent-payments/${props.match.params.rentPaymentUuid}`} />,
  '/rentals/:leaseUuid/security-deposit/:securityDepositUuid': (props: RouteComponentProps<{ leaseUuid: string; securityDepositUuid: string; }>) => <Redirect to={`${Globals.WEB_APARTMENT_DOMAIN}/rentals/${props.match.params.leaseUuid}/security-deposits/${props.match.params.securityDepositUuid}`} />,
  '/user/:tab?/:otherTab?/:otherOtherTab?': (props: RouteComponentProps<{ tab: string; otherTab: string; otherOtherTab: string; }>) => <Redirect to={`${Globals.WEB_APARTMENT_DOMAIN}/profile${props.match.params.tab ? `/${props.match.params.tab}` : ''}${props.match.params.otherTab ? `/${props.match.params.otherTab}` : ''}${props.match.params.otherOtherTab ? `/${props.match.params.otherOtherTab}` : ''}`} />,

  //
  // Content
  '/blog': loadable(() => import(/* webpackChunkName: "blog" */'~web-core/lib/common/scenes/Blog')),
  '/blog/:slugOrUuid': loadable(() => import(/* webpackChunkName: "blog-post" */'~web-core/lib/common/scenes/BlogPost')),
  '/learn/:categoryOrAnswerSlug?/:answerSlug?': loadable(() => import(/* webpackChunkName: "learn" */'~web-core/lib/common/scenes/Learn')),
  '/manual/:contentSectionSlug?/:contentAnswerSlug?': loadable(() => import(/* webpackChunkName: "manual" */'~web-core/lib/common/scenes/Manual')),
  '/questions/:slugOrUuid': loadable(() => import(/* webpackChunkName: "questions" */'~web-core/lib/common/scenes/Question')),
  '/support': loadable(() => import(/* webpackChunkName: "support" */'~web-core/lib/common/scenes/Support')),
  '/support/:categorySlug': loadable(() => import(/* webpackChunkName: "support-category" */'~web-core/lib/common/scenes/SupportCategory')),

  //
  // Services
  '/service-dispatches/:uuid/accept': loadable(() => import(/* webpackChunkName: "service-dispatch-accept" */'~web-core/lib/common/scenes/AcceptServiceDispatch')),
  '/service-fulfillments/:uuid/submit': loadable(() => import(/* webpackChunkName: "service-fulfillment-submit" */'~web-core/lib/common/scenes/SubmitServiceFulfillment')),

  //
  // Inspections
  '/inspections/create/:addressUnitUuid': loadable(() => import(/* webpackChunkName: "inspection-create" */'~web-core/lib/common/scenes/InspectionCreate')),
  '/inspections/:conditionInspectionUuid/setup/:step': loadable(() => import(/* webpackChunkName: "inspection-setup" */'~web-core/lib/common/scenes/InspectionSetup')),
  '/inspections/:conditionInspectionUuid/confirm': loadable(() => import(/* webpackChunkName: "inspection-confirmation" */'~web-core/lib/common/scenes/InspectionConfirmation')),

  //
  // Special routes
  '/documents/:documentUuid/sign': loadable(() => import(/* webpackChunkName: "documents-sign" */'~web-core/lib/common/scenes/SignDocument')),
  '/embedded-esign/:documentUuid': loadable(() => import(/* webpackChunkName: "embedded-esign-url" */'~web-core/lib/common/scenes/LeaseSignatureEmbed')),

  //
  // Redirects
  '/list': () => <Redirect to="/start" />,
  '/tutorials/:articleSlug': (props: RouteComponentProps<{ articleSlug: string }>) => <Redirect to={`/learn/subletting-your-apartment/${props.match.params.articleSlug}`} />,
  '/instant': () => <Redirect to="/for-leaseholders" />,
  '/instant/:takeoverRequestUuid/:tab?': () => <Redirect to="/for-leaseholders" />,
  '/takeover-requests/:takeoverRequestUuid': () => <Redirect to="/for-leaseholders" />,
  '/takeover-proposals/:takeoverProposalUuid': () => <Redirect to="/for-leaseholders" />,
  '/for-leaseholders': () => <Redirect to="/leaseholder" />,
  '/leaseholder': () => <Redirect to="/" />,
  '/for-landlords': () => <Redirect to="/landlord" />,
  '/rent': () => <Redirect to={`${Globals.WEB_APARTMENT_DOMAIN}`} />,
  '/start': () => <Redirect to={`${Globals.WEB_MANAGE_DOMAIN}/start`} />,
  '/select-plan/:addressUnitUuid': (props: RouteComponentProps<{ addressUnitUuid: string }>) => <Redirect to={`${Globals.WEB_MANAGE_DOMAIN}/select-plan/${props.match.params.addressUnitUuid}${props.location.search}`} />,
  '/select-plan/:addressUnitUuid/checkout': (props: RouteComponentProps<{ addressUnitUuid: string }>) => <Redirect to={`${Globals.WEB_MANAGE_DOMAIN}/select-plan/${props.match.params.addressUnitUuid}/checkout${props.location.search}`} />,
  '/plans/owner': () => <Redirect to="/" />,
  '/plans/entrepreneur': () => <Redirect to="/" />,
  '/ads.txt': () => <Redirect to="https://cdn4.buysellads.net/pub/caretaker.ads.txt" />,


  // Affiliate redirects
  '/biggerpockets': () => <Redirect to={`${Globals.WEB_CORE_DOMAIN}?utm_source=biggerpockets&utm_campaign=podcast`} />,
  '/stinson': () => <Redirect to={`${Globals.WEB_CORE_DOMAIN}?utm_source=partners&utm_campaign=stevestinson`} />,
  '/lite': () => <Redirect to="/owner" />,

  //
  // Old listing dashboard redirects
  '/listings': loadable(() => import(/* webpackChunkName: "dashboard-listing-redirect" */'~web-core/lib/common/scenes/_redirects/ListingDashboardRedirect')),
  '/listings/:listingUuid': loadable(() => import(/* webpackChunkName: "dashboard-listing-redirect" */'~web-core/lib/common/scenes/_redirects/ListingDashboardRedirect')),
  '/listings/:listingUuid/edit': loadable(() => import(/* webpackChunkName: "dashboard-listing-redirect" */'~web-core/lib/common/scenes/_redirects/ListingDashboardRedirect')),
  '/listings/:listingUuid/verify': loadable(() => import(/* webpackChunkName: "dashboard-listing-redirect" */'~web-core/lib/common/scenes/_redirects/ListingDashboardRedirect')),
  '/listings/:listingUuid/payouts': loadable(() => import(/* webpackChunkName: "dashboard-listing-redirect" */'~web-core/lib/common/scenes/_redirects/ListingDashboardRedirect')),
  '/listings/:listingUuid/overview': loadable(() => import(/* webpackChunkName: "dashboard-listing-redirect" */'~web-core/lib/common/scenes/_redirects/ListingDashboardRedirect')),
  '/listings/:listingUuid/tenants': loadable(() => import(/* webpackChunkName: "dashboard-listing-redirect" */'~web-core/lib/common/scenes/_redirects/ListingDashboardRedirect')),
  '/listings/:listingUuid/tenants/:flipUuid': loadable(() => import(/* webpackChunkName: "dashboard-listing-redirect" */'~web-core/lib/common/scenes/_redirects/ListingDashboardRedirect')),
  '/listings/:listingUuid/applicants': loadable(() => import(/* webpackChunkName: "dashboard-listing-redirect" */'~web-core/lib/common/scenes/_redirects/ListingDashboardRedirect')),
  '/listings/:listingUuid/applicants/:applicantUuid': loadable(() => import(/* webpackChunkName: "dashboard-listing-redirect" */'~web-core/lib/common/scenes/_redirects/ListingDashboardRedirect')),
  '/listings/:listingUuid/documents': loadable(() => import(/* webpackChunkName: "dashboard-listing-redirect" */'~web-core/lib/common/scenes/_redirects/ListingDashboardRedirect')),
  '/listings/:listingUuid/documents/:documentUuid': loadable(() => import(/* webpackChunkName: "dashboard-listing-redirect" */'~web-core/lib/common/scenes/_redirects/ListingDashboardRedirect')),
  '/listings/:listingUuid/rent-payments/:rentPaymentUuid': loadable(() => import(/* webpackChunkName: "dashboard-listing-redirect" */'~web-core/lib/common/scenes/_redirects/ListingDashboardRedirect')),
  '/listings/:listingUuid/security-deposits/:securityDepositUuid': loadable(() => import(/* webpackChunkName: "dashboard-listing-redirect" */'~web-core/lib/common/scenes/_redirects/ListingDashboardRedirect')),

  //
  // Cheating legacy routes (because I needed it to be wrapped with Stage)
  // '/profile/:uuid': loadable(() => import(/* webpackChunkName: "public-profile" */'~web-core/lib/_common/components/containers/routeHandlers/Profile')),

  //
  // Legacy routes
  '/applicants/:flipUuid': LegacyRouter,
  '/auth/linkedin': LegacyRouter,
  '/flips/:conversationUuid': LegacyRouter,
  '/start-a-rental/:listingUuid': LegacyRouter,
  '/start-a-rental/:listingUuid/invite': LegacyRouter,
  '/start-a-rental/:listingUuid/invite/:inviteUuid': LegacyRouter,
  '/messages/:conversationUuid': LegacyRouter,
  '/pay-rent/start': LegacyRouter,
  '/settings/:tab': LegacyRouter,
  '/settings': LegacyRouter,
  '/invite/:inviteUuid': LegacyRouter,
  '/learn/:stateSlug/:categorySlug/:slugOrUuid': LegacyRouter,
  '/pay-rent': LegacyRouter,
  '/questions/:stateSlug/:slugOrUuid': LegacyRouter,
  '/renters': LegacyRouter,
  '/rent-guarantee': LegacyRouter,
  '/search': LegacyRouter,
  '/sublet-agreement': LegacyRouter,
  '/terms': LegacyRouter,

  // Not found catchall
  '/*': loadable(() => import(/* webpackChunkName: "not-found" */'~web-core/lib/common/scenes/NotFound')),
};
