import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import Globals from '~web-core/lib/common/globals';

const client = Bugsnag.start({
  apiKey: Globals.BUGSNAG_KEY,
  appVersion: Globals.VERSION,
  logger: null,
  plugins: [
    new BugsnagPluginReact(),
  ],
  releaseStage: Globals.ENV,
});

export default client;
